import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import TechnicianAvatar from "./technicianAvatar";
import {
  getFeedbackReport,
  saveFeedbackReport,
} from "../services/feedbackService";
import useForm from "./common/form";
import Layout from "./common/layout";

const Survey = (props) => {
  const feedbackCode = props.match.params.code;
  const [technician, setTechnician] = useState({
    first_name: "",
    last_name: "",
    id: "",
  });
  const [completed, setCompleted] = useState(false);

  const submitForm = async () => {
    try {
      let surveyData = { ...form.data };

      delete surveyData.project_start;
      delete surveyData.project_finish;

      await saveFeedbackReport(feedbackCode, surveyData);
      setCompleted(true);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        form.setErrors(ex.response.data.errors);
      }
    }
  };

  const form = useForm({ submitForm: submitForm });

  useEffect(() => {
    async function setFormData() {
      try {
        const { data } = await getFeedbackReport(feedbackCode);

        form.setData(data.feedback);
        setTechnician(data.technician);
      } catch (ex) {
        if (ex.response) {
          switch (ex.response.status) {
            case 403:
              setCompleted(true);
              break;
            case 404:
              return props.history.replace("/not-found");
            default:
              return;
          }
        }
      }
    }

    setFormData();
  }, [feedbackCode]);

  if (completed) {
    return (
      <Layout title='Technician Feedback'>
        <Typography variant='h5' align='center' style={{ padding: "3rem" }}>
          Thank you for completing this feedback report.
        </Typography>
      </Layout>
    );
  } else {
    return (
      <Layout title='Technician Feedback'>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='stretch'
          spacing={3}
        >
          <SurveyRow>
            <Typography variant='h6' gutterBottom>
              Welcome to the Linc Technician feedback form. Please complete the
              below questions regarding your recent experience using one of our
              technicians.
            </Typography>
          </SurveyRow>
          <SurveyRow label='Technician Details'>
            <TechnicianAvatar {...technician} />
            <Typography variant='body1' gutterBottom>
              Project Dates: &nbsp;
              {form.data.project_start &&
                new Date(form.data.project_start).toLocaleDateString()}
              &nbsp;-&nbsp;
              {form.data.project_finish &&
                new Date(form.data.project_finish).toLocaleDateString()}
            </Typography>
          </SurveyRow>
          <SurveyRow label='Project Details'>
            {form.renderInput({
              name: "project",
              label: "Project name or code",
            })}
            {form.renderInput({
              name: "works",
              label: "What works were carried out?",
              helperText:
                "AV install, rack build and field installation, copper and fiber terminations.",
            })}
          </SurveyRow>
          <SurveyRow label='How would you rate our Technician?'>
            {form.renderSlider({
              name: "quality",
              label: "Quality of workmanship:",
            })}
            {form.renderSlider({
              name: "ethic",
              label: "Work ethic:",
            })}
            {form.renderSlider({
              name: "tidiness",
              label: "Tidiness:",
            })}
            {form.renderSlider({
              name: "reliability",
              label: "Reliability and timekeeping:",
            })}
            {form.renderSlider({
              name: "understanding",
              label: "Understanding of works:",
            })}
          </SurveyRow>
          <SurveyRow label='How was the final outcome of the works?'>
            {form.renderRadio({
              name: "outcome",
              options: [
                { label: "Above Expectations", value: 2 },
                { label: "As Expected", value: 1 },
                { label: "Below Expectations", value: 0 },
              ],
            })}
          </SurveyRow>
          <SurveyRow label='Based on your experience would you be happy to use this technician again?'>
            {form.renderRadio({
              name: "tech_again",
              options: [
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ],
            })}
          </SurveyRow>
          <SurveyRow label='Based on your experience would you use our services again?'>
            {form.renderRadio({
              name: "again",
              options: [
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ],
            })}
          </SurveyRow>
          <SurveyRow label='Comments or recommendations'>
            {form.renderInput({
              name: "comments",
              label: "",
              multiline: true,
              rows: 4,
              placeholder: "Comments",
            })}
          </SurveyRow>
          <SurveyRow>
            <Typography variant='body2'>
              Small text about how the results will be used.
            </Typography>
          </SurveyRow>
          <SurveyRow label='Survey Completed By'>
            {form.renderInput({
              name: "completed_by",
              label: "Name",
            })}
            {form.renderSignature({ label: "Signature" })}
          </SurveyRow>
          <SurveyRow>{form.renderSave({ label: "Submit Feedback" })}</SurveyRow>
        </Grid>
      </Layout>
    );
  }
};

export default Survey;

const SurveyRow = ({ label, children }) => {
  let elements = React.Children.toArray(children);

  return (
    <React.Fragment>
      {label && (
        <Grid item xs={12}>
          <Typography variant='h5'>{label}</Typography>
        </Grid>
      )}
      {elements.map((element, index) => (
        <Grid item xs={12} key={index}>
          {element}
        </Grid>
      ))}
    </React.Fragment>
  );
};
