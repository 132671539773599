import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "material-react-toastify";

import useForm from "./common/form";
import {
  getUser,
  saveUser,
  deleteAvatar,
  uploadAvatar,
  getAvatar,
} from "../services/userService";
import auth from "../services/authService";
import Layout from "./common/layout";
import { Grid, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ImageUpload from "./imageUpload";

const User = (props) => {
  const [user, setUser] = useState();
  const history = useHistory();

  const roles = [
    { name: "ROLE_USER", label: "View" },
    { name: "ROLE_TECHNICIAN", label: "Technician" },
    { name: "ROLE_ADMIN", label: "Admin" },
  ];

  const submitForm = async () => {
    try {
      let userData = { ...form.data };

      if (userData.password === "") {
        delete userData.password;
        delete userData.password_confirm;
      }

      delete userData.avatar;

      //Map form roles to json api roles
      if (user !== "current") {
        let jsonRoles = [];

        for (const role in form.data.roles) {
          form.data.roles[role] && jsonRoles.push(role);
        }

        userData["roles"] = jsonRoles;
      }

      const { status } = await saveUser(userData);

      if (status === 201) {
        window.location = "/users";
      } else {
        toast.success("Successfully updated.");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        form.setErrors(ex.response.data.errors);
      }
    }
  };

  const form = useForm({ submitForm: submitForm });

  useEffect(() => {
    async function populateUser(currentUser = false) {
      const { data } = await getUser(userId);

      let user = data.user;

      //Map json api roles to form roles
      if (!currentUser) {
        let newRoles = roles.reduce((acc, role) => {
          acc[role.name] = data.user.roles.includes(role.name);
          return acc;
        }, {});
        user["roles"] = newRoles;
      }

      form.setData(user);
    }

    let userId = props.match.params.id;

    switch (userId) {
      case undefined:
        userId = auth.getCurrentUser().id;
        populateUser(true);
        setUser("current");
        break;
      case "new":
        setUser("new");
        break;
      default:
        populateUser();
        setUser("id");
        break;
    }
  }, [props]);

  return (
    <Layout
      title={
        user === "new"
          ? "Create User"
          : user !== "current"
          ? "Edit User"
          : "My Account"
      }
    >
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => history.goBack()}
            startIcon={<ArrowBack />}
          >
            Back{" "}
            {history.location.state &&
              "to " + history.location.state.fromTitle.split("|").pop()}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {" "}
          {form.renderInput({
            name: "first_name",
            label: "First Name",
            type: "text",
            helperText: "First name or company",
          })}
        </Grid>
        <Grid item xs={12}>
          {" "}
          {form.renderInput({
            name: "last_name",
            label: "Last Name",
            type: "text",
          })}
        </Grid>
        <Grid item xs={12}>
          {" "}
          {form.renderInput({
            name: "password",
            label: "New Password",
            type: "password",
          })}
        </Grid>
        <Grid item xs={12}>
          {" "}
          {form.renderInput({
            name: "password_confirm",
            label: "Confrim Password",
            type: "password",
          })}
        </Grid>
        <Grid item xs={12}>
          {" "}
          {form.renderInput({
            name: "email",
            label: "Email",
            type: "email",
          })}
        </Grid>
        {form.data.id && (
          <Grid item xs={12}>
            <ImageUpload
              getService={() => getAvatar(form.data.id)}
              uploadService={(file, onUploadProgress) =>
                uploadAvatar(form.data.id, file, onUploadProgress)
              }
              deleteService={() => deleteAvatar(form.data.id)}
              types={["image/jpeg"]}
              multiple={false}
              showFilename={false}
              label='Avatar'
              helper='Drag or click above to upload avatar image, previous image will be replaced.'
            />
          </Grid>
        )}
        {user !== "current" && (
          <Grid item xs={12}>
            {" "}
            {form.renderCheckboxGroup({
              options: roles,
              name: "roles",
              label: "Roles",
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          {" "}
          {form.renderSave({
            label:
              user === "new"
                ? "Create User"
                : user !== "current"
                ? "Save User"
                : "Save",
          })}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default User;
