import httpService from "./httpService";

const apiEndpoint = "/feedback";

export function getFeedbackReport(feedbackCode){
    return httpService.get(apiEndpoint + '/' + feedbackCode);
}

export function getFeedback(){
    return httpService.get(apiEndpoint);
}

export function saveFeedbackReport(feedbackCode, feedback){
    return httpService.put('/client-feedback/' + feedbackCode, feedback);
}

export function createFeedbackReport(feedback){
    return httpService.post(apiEndpoint, feedback);
}

export function updateFeedbackReport(feedback, id){
    return httpService.put(apiEndpoint + '/' + id, feedback);
};

export function deleteFeedbackReport(reportId){
    return httpService.delete(apiEndpoint + '/' + reportId);
}