import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "./common/layout";
import TechnicianRating from "./technicianRating";
import { getLeaderboard } from "../services/technicianService";
import { Button, Grid } from "@material-ui/core";
import TechnicianAvatar from "./technicianAvatar";
import auth from "../services/authService";

function Leaderboard() {
  const [technicianList, setTechnicianList] = useState(false);
  useEffect(() => {
    async function setLeaderboard() {
      try {
        const { data } = await getLeaderboard();

        setTechnicianList(data.leaderboard);
      } catch (ex) {}
    }

    setLeaderboard();
  }, []);

  const classes = useStyles();

  return (
    <Layout title='Leaderboard' usePaper={false}>
      {technicianList &&
        technicianList.map((technician) => {
          return (
            <Paper className={classes.paper} key={technician.id}>
              <Grid container spacing={2} direction='row' alignItems='center'>
                <Grid item xs={12} sm={9}>
                  <TechnicianAvatar {...technician} />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.button}>
                  {auth.hasRole("ROLE_ADMIN") && (
                    <Link
                      to={{
                        pathname: "/technician/" + technician.id,
                        state: { fromTitle: document.title },
                      }}
                    >
                      <Button variant='contained' color='primary'>
                        View Technician
                      </Button>
                    </Link>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TechnicianRating {...technician}></TechnicianRating>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
    </Layout>
  );
}

export default Leaderboard;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(2),
  },
  button: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
}));
