import axios from "axios";
import { toast } from "material-react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error("Unexpected error occurred.");
  }

  if(error.response && (error.response.status === 401)){
    //window.location = "/unauthorised";
  }

  if(error.response && (error.response.status === 404)){
    //window.location = "/not-found";
  }

  return Promise.reject(error);
});


const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
  };

export default httpService;
