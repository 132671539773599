import React from "react";

import { Grid } from "@material-ui/core";
import Rating from "./rating";
import Typography from "@material-ui/core/Typography";

function TechnicianRating({
  quality,
  ethic,
  reliability,
  tidiness,
  understanding,
  total = false,
}) {
  let overall = total
    ? total
    : (quality + ethic + reliability + tidiness + understanding) / 5;

  return (
    <Grid container spacing={2} direction='row' alignItems='center'>
      <Grid item xs={12} lg={2}>
        <Typography variant='h6' align='center' gutterBottom>
          <strong>Overall</strong>
        </Typography>
        <Rating
          numerator={overall}
          denominator={10}
          decimalPlaces={1}
          size='150px'
        />
      </Grid>
      <Grid item xs={12} lg={10}>
        <Grid
          container
          item
          direction='row'
          alignItems='center'
          justify='space-around'
          spacing={3}
        >
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Typography variant='h6' align='center' gutterBottom>
              Quality
            </Typography>
            <Rating numerator={quality} denominator={10} size='100px' />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Typography variant='h6' align='center' gutterBottom>
              Work Ethic
            </Typography>
            <Rating numerator={ethic} denominator={10} size='100px' />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Typography variant='h6' align='center' gutterBottom>
              Reliability
            </Typography>
            <Rating numerator={reliability} denominator={10} size='100px' />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Typography variant='h6' align='center' gutterBottom>
              Tidiness
            </Typography>
            <Rating numerator={tidiness} denominator={10} size='100px' />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Typography variant='h6' align='center' gutterBottom>
              Understanding
            </Typography>
            <Rating numerator={understanding} denominator={10} size='100px' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TechnicianRating;
