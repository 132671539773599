import httpService from "./httpService";

const apiEndpoint = "/technician";

export function getLeaderboard(){
    return httpService.get(apiEndpoint + '/leaderboard');
}

export function getTechnicians(){
    return httpService.get(apiEndpoint);
}

export function getTechnician(technicianId){
    return httpService.get(apiEndpoint + '/' + technicianId);
}

export function getTechnicianFeedback(technicianId){
    return httpService.get(apiEndpoint + '/' + technicianId + '/feedback');
}
