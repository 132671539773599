import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";

import SignaturePad from "react-signature-canvas";

const useForm = ({ submitForm }) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const classes = useStyles();
  const [sigPad, setSigPad] = useState();

  useEffect(() => {
    if (sigPad && data.signature) {
      sigPad.fromDataURL(data.signature);
    }
  }, [data.signature, sigPad]);

  useEffect(() => {
    var firstError = document.querySelector(".Mui-error");
    firstError && firstError.scrollIntoView(false);
  }, [errors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (sigPad) {
      const newData = { ...data };

      if (sigPad.isEmpty()) {
        newData.signature = null;
      } else {
        newData.signature = sigPad.toDataURL();
      }

      setData(newData);
    }
    setErrors({ errors: {} });
    setSubmit(true);
  };

  useEffect(() => {
    if (submit) {
      setSubmit(false);
      submitForm();
    }
  }, [submit]);

  const handleChange = (event) => {
    const input = event.target;
    const newData = { ...data };

    newData[input.name] = input.value;

    setData(newData);
  };

  const handleSliderChange = (name, newValue) => {
    const newData = { ...data };
    newData[name] = newValue;
    setData(newData);
  };

  const handleRadioChange = (name, event) => {
    const newData = { ...data };
    newData[name] = parseInt(event.target.value);
    setData(newData);
  };

  const handleComboChange = (name, newValue) => {
    const newData = { ...data };
    newData[name] = newValue;
    setData(newData);
  };

  const handleSwitchChange = (event) => {
    const newData = { ...data };
    newData[event.target.name] = event.target.checked;
    setData(newData);
  };

  const handleCheckboxGroupChange = (name, event) => {
    const groupData = { ...data[name] };

    groupData[event.target.name] = event.target.checked;
    setData({ ...data, [name]: { ...groupData } });
  };

  const setSignature = (signature) => {
    if (sigPad) {
      sigPad.fromDataURL(signature);
    }
  };

  const clearSignature = () => {
    sigPad.clear();
  };

  const renderSave = ({ label, ...rest }) => {
    return (
      <Button
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        type='submit'
        {...rest}
      >
        {label}
      </Button>
    );
  };

  const renderInput = ({
    name,
    label,
    type = "text",
    adornments = { start: false, end: false, adornment: "" },
    helperText,
    ...rest
  } = {}) => {
    let inputProps = {};
    if (adornments.start) {
      inputProps = {
        startAdornment: (
          <InputAdornment position='start'>
            {adornments.adornment}
          </InputAdornment>
        ),
      };
    }

    return (
      <TextField
        name={name}
        value={data[name] || ""}
        label={label}
        type={type}
        onChange={handleChange}
        error={errors[name] && true}
        helperText={errors[name] || helperText}
        InputProps={inputProps}
        fullWidth
        variant='outlined'
        {...rest}
      />
    );
  };

  const renderSlider = ({ name, label }) => {
    return (
      <Grid item container justify='flex-start' alignItems='center' spacing={4}>
        <Grid item xs={12} md={4}>
          <Typography variant='h6' noWrap>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} style={{ paddingRight: "1.5rem" }}>
          <Slider
            valueLabelDisplay='on'
            value={data[name] || 0}
            step={1}
            min={0}
            max={10}
            marks={[
              { value: 0, label: "0" },
              { value: 10, label: "10" },
            ]}
            onChange={(event, newValue) => handleSliderChange(name, newValue)}
            style={{ marginTop: "1.5rem" }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderRadio = ({ name, options }) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          name={name}
          value={data[name] || 0}
          onChange={(event) => handleRadioChange(name, event)}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel
              value={value}
              control={<Radio color='primary' />}
              label={label}
              key={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderCombo = ({ name, label, options, optionData, optionLabel }) => {
    const selected = options.findIndex((option) => option.data === data[name]);
    return (
      <Autocomplete
        value={options[selected] || null}
        onChange={(event, newValue) => {
          newValue !== null
            ? handleComboChange(name, newValue[optionData])
            : handleComboChange(name, null);
        }}
        getOptionLabel={(option) => option[optionLabel]}
        id={name}
        options={options}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            value={options[optionData]}
            variant='outlined'
            error={errors[name] && true}
            helperText={errors[name]}
          />
        )}
      />
    );
  };
  const renderSwitch = ({ name, label }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={data[name] || false}
            onChange={handleSwitchChange}
            color='primary'
            name={name}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label={label}
      />
    );
  };

  const renderCheckboxGroup = ({ options, name, label }) => {
    return (
      <FormControl required error={errors[name] && true} component='fieldset'>
        <FormLabel component='legend'>{label}</FormLabel>
        <FormGroup>
          {options.map(({ name: boxName, label: boxLabel }) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data[name] ? data[name][boxName] || false : false}
                    onChange={(event) => handleCheckboxGroupChange(name, event)}
                    name={boxName}
                  />
                }
                label={boxLabel}
                key={boxName}
              />
            );
          })}
        </FormGroup>
        <FormHelperText>{errors[name]}</FormHelperText>
      </FormControl>
    );
  };

  const renderSignature = ({ label }) => {
    return (
      <React.Fragment>
        <div
          className={`${classes.sigPad} ${errors["signature"] ? "error" : ""}`}
        >
          <label className='sigLabel'>{label}</label>
          <SignaturePad
            clearOnResize={false}
            maxWidth={2}
            throttle={0}
            ref={(ref) => {
              setSigPad(ref);
            }}
          />
        </div>
        {errors["signature"] && (
          <Typography
            variant='caption'
            color='error'
            className={classes.errorText}
          >
            {errors["signature"]}
          </Typography>
        )}
        <Button
          size='small'
          variant='outlined'
          color='primary'
          onClick={clearSignature}
          style={{ display: "block", marginTop: "5px" }}
        >
          Clear
        </Button>
      </React.Fragment>
    );
  };

  return {
    data,
    setData,
    renderInput,
    renderSlider,
    renderRadio,
    renderCombo,
    renderSwitch,
    renderCheckboxGroup,
    renderSignature,
    renderSave,
    setErrors,
    setSignature,
    sigPad,
  };
};

export default useForm;

const useStyles = makeStyles((theme) => ({
  sigPad: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "inline-block",
    position: "relative",
    padding: "4px",
    color: "#00000085",

    "& .sigLabel": {
      position: "absolute",
      top: "-8px",
      left: "8px",
      fontSize: "0.9em",
      lineHeight: "1rem",
      backgroundColor: "#fff",
      padding: "0 5px",
      color: "inherit",
    },

    "&:hover": {
      borderColor: "#000000de",
    },

    "&:active": {
      borderColor: "transparent",
      boxShadow: "inset 0 0 0 2px " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },

    "&.error": {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      "&:active": {
        borderColor: "transparent",
        boxShadow: "inset 0 0 0 2px " + theme.palette.error.main,
      },
    },
  },
  errorText: {
    display: "block",
    margin: "3px 14px",
  },
}));
