import { Typography } from "@material-ui/core";
import React from "react";

import Layout from "./common/layout";

const NotFound = () => {
  return (
    <Layout title='404 - Page not Found'>
      <Typography variant='h5'>
        Sorry, the page you are looking for cannot be found.
      </Typography>
    </Layout>
  );
};

export default NotFound;
