import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Layout from "./common/layout";
import { Grid, Button, Paper } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Feedback from "./feedback";
import auth from "../services/authService";

import {
  getTechnician,
  getTechnicianFeedback,
} from "../services/technicianService";
import TechnicianAvatar from "./technicianAvatar";
import TechnicianRating from "./technicianRating";

function Technician(props) {
  const [technician, setTechnician] = useState();
  const [feedback, setFeedback] = useState();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);

  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    async function populateTechnician(id) {
      try {
        const { data } = await getTechnician(id);

        setTechnician(data.technician);
      } catch (ex) {}
    }

    async function populateFeedback(id) {
      try {
        const { data } = await getTechnicianFeedback(id);

        setFeedback(data.feedback);
      } catch (ex) {}
    }

    let technicianId = props.match.params.id;

    if (technicianId === undefined) {
      technicianId = auth.getCurrentUser().id;
    }

    populateTechnician(technicianId);

    if (auth.hasRole("ROLE_ADMIN")) {
      populateFeedback(technicianId);
    }
  }, [props]);

  return (
    <Layout title='Technician Feedback'>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => history.goBack()}
            startIcon={<ArrowBack />}
          >
            Back{" "}
            {history.location.state &&
              "to " + history.location.state.fromTitle.split("|").pop()}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TechnicianAvatar {...technician} />
        </Grid>
        <Grid item xs={12}>
          <TechnicianRating {...technician} />
        </Grid>
        {feedback && (
          <Grid item xs={12}>
            <Typography variant='h4'>Feedback</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {feedback &&
            feedback.map((fb) => {
              return (
                <Accordion
                  key={fb.id}
                  expanded={expanded === fb.id}
                  onChange={handleAccordion(fb.id)}
                >
                  <AccordionSummary
                    style={{ backgroundColor: "#eee" }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant='body1'>
                      <strong>{fb.client} </strong>
                      {" , "}
                      {fb.project} {" - "}
                      {new Date(fb.project_start).toLocaleDateString("en-GB")}
                      {" - "}
                      {new Date(fb.project_finish).toLocaleDateString("en-GB")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      {expanded === fb.id && <Feedback {...fb} />}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Technician;
