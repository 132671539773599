import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const DialogContext = React.createContext();

export const DialogProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Dialog Title");
  const [text, setText] = useState("Dialog Text");
  const [yes, setYes] = useState("Yes");
  const [no, setNo] = useState("No");
  const promiseInfo = useRef({});

  const openDialog = async ({
    title: _title = "Title",
    text: _text = "Text",
    yesLabel = "Yes",
    noLabel = "No",
  }) => {
    setTitle(_title);
    setText(_text);
    setYes(yesLabel);
    setNo(noLabel);
    setOpen(true);

    return new Promise((resolve, reject) => {
      promiseInfo.current = {
        resolve,
        reject,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
    promiseInfo.current.reject();
  };

  const handleYes = () => {
    setOpen(false);
    promiseInfo.current.resolve("success");
  };

  const handleNo = () => {
    handleClose();
  };

  return (
    <DialogContext.Provider value={{ openDialog }}>
      {props.children}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color='primary'>
            {no}
          </Button>
          <Button onClick={handleYes} color='primary' autoFocus>
            {yes}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};
