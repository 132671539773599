class SimpleDnd{
    
    constructor({containerId, addingClass = false, sortingClass = false, addMargin = false, dragClass = false, sortCallback = false, addCallback = false}){

    this.containerId = containerId;
    this.addingClass = addingClass; 
    this.sortingClass = sortingClass; 
    this.dragClass = dragClass; 
    this.sortCallback = sortCallback;
    this.addCallback = addCallback;
    this.addMargin = addMargin;
    this.dragSelf = false;
    this.containerElements = [];
    this.container = undefined;
    this.dragElement = undefined;
    this.dragLevel = 0;
    
    }
    
    //TODO: resolve drag leave issue and pointer events. Look at scope

    getContainer = () =>{
        if (this.container === undefined){
            let _container = document.getElementById(this.containerId);
            this.container = (_container);
        }
        return this.container;
    }

    handleDragStart(e){
        this.dragClass && e.currentTarget.classList.add(this.dragClass);

        e.dataTransfer.setData(this.containerId, e.currentTarget.dataset.id);
        e.dataTransfer.setData("position", e.currentTarget.dataset.position);
        
        this.sortingClass && this.getContainer().classList.add(this.sortingClass);
    };
    
    handleDragEnd(e){
        this.dragClass && e.currentTarget.classList.remove(this.dragClass);
        this.sortingClass &&  this.getContainer().classList.remove(this.sortingClass);
    };

    handleDragEnter(e){
        e.preventDefault();
        e.stopPropagation();
        
        this.containerElements = this.getContainerElements();
        this.dragElement = this.dragClass ? document.querySelector("." + this.dragClass) : undefined;
        this.dragSelf = this.isDragSelf(e.dataTransfer.types);
        
        if (!this.dragSelf) {
          this.addingClass && this.getContainer().classList.add(this.addingClass);
        }

        this.dragLevel++;

    };
    
    handleDragLeave(e){
        e.preventDefault();
        e.stopPropagation();

        this.dragLevel--;

        if (!this.dragSelf && this.dragLevel === 0) {
            this.addMargin && this.clearMargins();
            this.addingClass && this.getContainer().classList.remove(this.addingClass);
        }

        
    };

    getContainerElements(){
        const domElements = [...this.getContainer().querySelectorAll("[draggable]")];
        let elements = [];
        for (const element of domElements) {
            const box = element.getBoundingClientRect();
            elements.push({ element: element, center: box.top + box.height / 2 });
        }
        return elements;
    };

    //Find the element that the draggable is above
    getDragAboveElement(y){
        
        return this.containerElements.reduce(
            (closest, element) => {
                const offset = y - element.center;
                if (offset < 0 && offset > closest.offset) {
                    return { offset: offset, element: element.element };
                } else {
                    return closest;
                }
            },
            { offset: Number.NEGATIVE_INFINITY }
        ).element;
    };

    handleDragOver(e){
        e.preventDefault();
        e.stopPropagation();
        if(this.dragLevel > 0){
            let aboveElement = this.getDragAboveElement(e.clientY);
        
            if (this.dragSelf) {
                this.handleSorting(aboveElement);
            } else {
                this.handleAdding(aboveElement);
            }
        }
    };

    handleAdding(aboveElement){
        this.addMargin && this.clearMargins();
        if (this.containerElements.length !== 0) {
            if (aboveElement === undefined) {
                this.containerElements[
                    this.containerElements.length - 1
                ].element.style.marginBottom = this.addMargin;
            } else {
              aboveElement.style.marginTop = this.addMargin;
            }
        }
    };

    handleSorting(aboveElement){
        if (aboveElement === undefined) {
            this.getContainer().appendChild(this.dragElement);
        } else {
            this.getContainer().insertBefore(this.dragElement, aboveElement);
        }
    };

    handleDrop(e){
        e.preventDefault();
        e.stopPropagation();

        let aboveElement = this.getDragAboveElement(e.clientY);
    
        const toPosition = aboveElement === undefined ? "end" : aboveElement.dataset.position;
    
        if (this.dragSelf) {
            const fromPosition = e.dataTransfer.getData("position");
            this.sortCallback(e.dataTransfer, fromPosition, toPosition, );
        } else {
            this.addMargin && this.clearMargins();
            this.getContainer().classList.remove(this.addingClass);
            this.addCallback(e.dataTransfer, toPosition);
        }
    };

    clearMargins(){
        this.containerElements.forEach((containerElement) => {
            containerElement.element.style.cssText = "";
        });
    };
    
    isDragSelf(dataTypes){
        for (const dataType of dataTypes) {
            if (dataType === this.containerId) {
                return true;
            }
        }
        return false;
    };

    containerProps(){
        return {
            onDrop:this.handleDrop.bind(this),
            onDragOver:this.handleDragOver.bind(this),
            onDragEnter:this.handleDragEnter.bind(this),
            onDragLeave:this.handleDragLeave.bind(this),
            id:this.containerId,
        }
    };

    draggableProps () {
        return{
            draggable: "true",
            onDragStart: this.handleDragStart.bind(this),
            onDragEnd:this.handleDragEnd.bind(this),
        }
    };
};

export default SimpleDnd;