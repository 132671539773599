import React, { useState, useEffect, useContext, useRef } from "react";

import { toast } from "material-react-toastify";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContext } from "./common/dialog";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import SimpleDnd from "./common/simpleDnd";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// Useful mime types
// "image/gif","image/jpeg","image/png"
// "text/plain", "text/csv", "text/html".
// "video/mp4", "application/pdf", "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint"
// "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
// "application/vnd.openxmlformats-officedocument.presentationml.presentation"
// "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

//getService must return array of file objects {url, name}
//uploadService must accept a file object and on progress callback

function ImageUpload({
  getService,
  uploadService,
  deleteService,
  types = [],
  multiple = true,
  limit = 0,
  showFilename = true,
  label = "File Upload",
  helper = "Drag or click above to upload files",
}) {
  const [files, setFiles] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [existing, setExisting] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const classes = useStyles();
  const inputRef = useRef(null);

  const { openDialog } = useContext(DialogContext);

  const dropFiles = (data, position) => {
    selectFiles(data.files);
  };

  const simpleDnd = new SimpleDnd({
    containerId: "avatar",
    addingClass: classes.adding,
    addCallback: dropFiles,
  });

  const handleSelect = (event) => {
    selectFiles(event.target.files);
  };

  const isAccepted = (fileType) => {
    if (fileTypes.includes(fileType)) {
      return true;
    }
    return false;
  };

  const selectFiles = async (fileList) => {
    let uploadData = [...uploads];
    let uploadFiles = [...files];

    let fileCount = uploadFiles.length;

    for (const file of fileList) {
      if (limit > 0 && fileCount >= limit) {
        toast.error("Only " + limit + " files may be uploaded at a time.");
        break;
      }

      if (multiple === false && fileCount >= 1) {
        toast.error("Only 1 file may be uploaded.");
        break;
      }

      if (uploads.findIndex((upload) => upload.name === file.name) !== -1) {
        toast.error(file.name + " already added to upload list.");
        continue;
      }

      if (!isAccepted(file.type)) {
        toast.error(file.name + " is not of an allowed type.");
        continue;
      }

      if (file.size > 3000000) {
        toast.error(file.name + " exeeds the maximum upload limit of 3mb.");
        continue;
      }

      if (existing.findIndex((x) => x.name === file.name) !== -1) {
        try {
          await openDialog({
            title: "Overwite Exisiting File?",
            text:
              "A file with this name already exisits, do you wan to replace it?",
          });
        } catch (ex) {
          continue;
        }
      }

      uploadData.push({
        name: file.name,
        percentage: 0,
        error: false,
        img: URL.createObjectURL(file),
      });

      uploadFiles.push(file);
      fileCount++;
    }
    setUploads(uploadData);
    setFiles(uploadFiles);
  };

  const openSelect = () => {
    inputRef.current.click();
  };

  const uploadImages = (event) => {
    event.stopPropagation();
    event.preventDefault();

    for (const file of files) {
      upload(file);
      setUploading(true);
    }
  };

  const updateProgress = ({ event, file, error = false }) => {
    setUploads((prevUploads) => {
      let progress = [...prevUploads];
      let fileIndex = progress.findIndex((info) => info.name === file.name);

      if (error) {
        progress[fileIndex].error = true;
      } else {
        progress[fileIndex].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
      }
      return progress;
    });
  };

  const upload = (file) => {
    uploadService(file, (event) => updateProgress({ event: event, file: file }))
      .then(() => {
        toast.success("Upload successful: " + file.name);
      })
      .catch(() => {
        updateProgress({ file: file, error: true });
        toast.error("Could not upload the image: " + file.name);
      })
      .finally(() => {
        removeFromList(file.name, "files");
      });
  };

  const removeFromList = (filename, list) => {
    let newList = [];

    switch (list) {
      case "files":
        newList = [...files];
        break;
      case "uploads":
        newList = [...uploads];
        break;
      default:
        newList = [...existing];
    }

    let listIndex = newList.findIndex((x) => x.name === filename);
    newList.splice(listIndex, 1);

    switch (list) {
      case "files":
        setFiles(newList);
        break;
      case "uploads":
        setUploads(newList);
        break;
      default:
        setExisting(newList);
    }
  };

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const list = event.currentTarget.dataset.list;
    const filename = event.currentTarget.dataset.name;

    if (list === "upload") {
      removeFromList(filename, "files");
      removeFromList(filename, "uploads");
    } else {
      deleteService(filename)
        .then(() => {
          toast.success(filename + " deleted");
          removeFromList(filename, "exisiting");
        })
        .catch(() => toast.error("Could not delete file."));
    }
  };

  useEffect(() => {
    setFileTypes(types);
    getService().then((response) => {
      setExisting(response.data.images);
    });
  }, []);

  useEffect(() => {
    if (uploading && files.length === 0) {
      toast.info("Uploads complete.");
      getService().then((response) => {
        setExisting(response.data.images);
      });
      setUploading(false);
      setUploads([]);
    }
  }, [files]);

  const renderFiles = (filesArr, prefix) => {
    return filesArr.map((file, index) => (
      <Grid
        item
        key={prefix + index}
        xs={4}
        sm={3}
        md={2}
        lg={1}
        className={classes.file}
      >
        <div className='icon'>
          <div className='wrapper'>
            <div>
              <img src={file.url ? file.url : file.img} alt={file.name} />
            </div>
            {file.url ? (
              <div className='overlay'>
                <div className='wrapper'>
                  <IconButton
                    color='primary'
                    data-name={file.name}
                    onClick={handleDelete}
                    data-list={"existing"}
                    className='delete'
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className='overlay upload'>
                <div className='wrapper'>
                  {file.error ? (
                    <CancelIcon color='primary' />
                  ) : file.percentage === 100 ? (
                    <CheckCircleIcon color='primary' />
                  ) : uploading ? (
                    <CircularProgress
                      variant='determinate'
                      value={file.percentage}
                    />
                  ) : (
                    <IconButton
                      color='primary'
                      data-name={file.name}
                      data-list={"upload"}
                      onClick={handleDelete}
                      className='delete'
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {showFilename && <div className='filename'>{upload.name}</div>}
      </Grid>
    ));
  };

  return (
    <React.Fragment>
      <Paper variant='outlined' className={classes.outlined}>
        <div className={classes.label}>{label}</div>
        <input
          type='file'
          multiple={multiple}
          accept={[...fileTypes]}
          onChange={handleSelect}
          ref={inputRef}
          style={{ display: "none" }}
        />

        <Grid
          id='drop-area'
          container
          alignContent='center'
          spacing={2}
          onClick={openSelect}
          {...simpleDnd.containerProps()}
          style={{ minHeight: "3rem" }}
        >
          {existing && renderFiles(existing, "ex")}
          {uploads && renderFiles(uploads, "up")}
          {files.length > 0 && (
            <Grid item xs={12}>
              <Button
                color='primary'
                variant='contained'
                onClick={uploadImages}
              >
                Upload New Files
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
      <div className={classes.instructions}>{helper}</div>
    </React.Fragment>
  );
}

export default ImageUpload;

const useStyles = makeStyles((theme) => ({
  outlined: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    position: "relative",
    border: "1px solid #00000036",
  },
  file: {
    "& .icon": {
      position: "relative",
      overflow: "hidden",
      textAlign: "center",
      paddingBottom: "100%",
      width: "100%",
      height: "0",

      "& .wrapper": {
        display: "flex",
        position: "absolute",
        height: "100%",
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      },

      "& img": {
        maxWidth: "100%",
      },

      "& .overlay": {
        position: "absolute",
        top: 0,
        color: "#fff",
        height: "100%",
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "center",

        "& .delete": {
          display: "none",
        },

        "&:hover": {
          backgroundColor: "#ffffff80",
          "& .delete": {
            display: "block",
          },
        },
      },

      "& .overlay.upload": {
        backgroundColor: "#ffffff80",
      },
    },

    "& .filename": {
      fontSize: "0.7rem",
      overflowWrap: "anywhere",
      textAlign: "center",
    },
  },
  adding: {
    backgroundColor: "#ccc",
  },
  label: {
    position: "absolute",
    top: "-8px",
    left: "8px",
    fontSize: "0.85em",
    lineHeight: "1rem",
    backgroundColor: "#fff",
    padding: "0 5px",
    color: "#0000008a",
  },
  instructions: {
    color: "#0000008a",
    marginTop: "3px",
    marginLeft: "14px",
    marginRight: "14px",
    fontSize: "0.75rem",

    lineHeight: "1.66",
  },
}));
