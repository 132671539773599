import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function TechnicianAvatar({ first_name, last_name, id, avatar }) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='center'
      spacing={2}
    >
      <Grid item>
        <Avatar src={avatar ? avatar.url : ""} className={classes.avatar}>
          {first_name && first_name.charAt(0)}
          {last_name && last_name.charAt(0)}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant='h6'>
          {first_name} {last_name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TechnicianAvatar;

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#fff",
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    "& .MuiAvatar-img": {
      backgroundColor: "#fff",
    },
  },
}));
