import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Button from "@material-ui/core/Button";

import { toast } from "material-react-toastify";

import {
  getFeedbackReport,
  createFeedbackReport,
  updateFeedbackReport,
} from "../services/feedbackService";
import { getTechnicians } from "../services/technicianService";
import useForm from "./common/form";
import Feedback from "./feedback";
import Layout from "./common/layout";
import { Divider } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router";

const Report = (props) => {
  const feedbackId = props.match.params.id;
  const [technicians, setTechnicians] = useState([]);
  const [feedback, setFeedback] = useState({});
  const history = useHistory();

  const submitForm = async () => {
    try {
      if (feedbackId === "new") {
        const { data } = await createFeedbackReport(form.data);
        props.history.replace("/report/" + data.id, {
          fromTitle: props.history.location.state.fromTitle,
        });
      } else {
        await updateFeedbackReport(form.data, feedbackId);
        toast.success("Report successfully updated.");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        form.setErrors(ex.response.data.errors);
      }
    }
  };

  const form = useForm({ submitForm: submitForm });

  useEffect(() => {
    async function populateTechnicians() {
      const { data } = await getTechnicians();
      setTechnicians(data.technicians);
    }

    async function populateReport() {
      const { data } = await getFeedbackReport(feedbackId);

      data.feedback.code =
        window.location.origin + "/survey/" + data.feedback.code;

      setFeedback(data.feedback);
      form.setData({
        user_id: data.technician.user_id,
        client: data.feedback.client,
        completed: data.feedback.completed,
        project_start: new Date(data.feedback.project_start).toLocaleDateString(
          "en-CA"
        ),
        project_finish: new Date(
          data.feedback.project_finish
        ).toLocaleDateString("en-CA"),
      });
    }

    if (feedbackId !== "new") {
      populateReport();
    } else {
      form.setData({
        user_id: "",
        client: "",
        completed: false,
        project_start: "",
        project_finish: "",
      });
    }
    populateTechnicians();
  }, [feedbackId]);

  const copyLink = () => {
    navigator.clipboard.writeText(feedback.code);
  };

  const renderFeedbackDetails = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant='h6' component='span'>
            Survey Link:{" "}
          </Typography>
          <Typography variant='body1' component='span'>
            <a
              href={feedback.code}
              target='_blank'
              rel='noreferrer'
              style={{
                overflowWrap: "anywhere",
                marginRight: "1rem",
                marginBottom: "1rem",
              }}
            >
              {feedback.code}
            </a>

            <Button
              variant='outlined'
              onClick={copyLink}
              startIcon={<PostAddIcon />}
            >
              Copy to Clipboard
            </Button>
          </Typography>
          <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Feedback</Typography>
        </Grid>
        <Grid item xs={12}>
          {form.data.completed ? (
            <Feedback {...feedback} />
          ) : (
            <Typography variant='h6'>Feedback not yet completed</Typography>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Layout title='Technician Feedback Report'>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => history.goBack()}
            startIcon={<ArrowBack />}
          >
            Back{" "}
            {history.location.state &&
              "to " + history.location.state.fromTitle.split("|").pop()}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Report Details</Typography>
        </Grid>
        <Grid item xs={12}>
          {form.renderCombo({
            name: "user_id",
            label: "Technician",
            options: technicians.reduce((dataArr, technician, index) => {
              dataArr[index] = {
                data: technician.id,
                label: technician.first_name + " " + technician.last_name,
              };
              return dataArr;
            }, []),
            optionData: "data",
            optionLabel: "label",
          })}
        </Grid>
        <Grid item xs={12}>
          {form.renderInput({
            name: "client",
            label: "Client",
            type: "text",
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {form.renderInput({
            name: "project_start",
            label: "Project Start",
            type: "date",
            InputLabelProps: {
              shrink: true,
            },
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {form.renderInput({
            name: "project_finish",
            label: "Project Finish",
            type: "date",
            InputLabelProps: {
              shrink: true,
            },
          })}
        </Grid>
        <Grid item xs={6}>
          {form.renderSwitch({
            name: "completed",
            label: "Completed",
          })}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {form.renderSave({
            label: feedbackId === "new" ? "Create Report" : "Update Report",
          })}
        </Grid>
        {feedbackId !== "new" ? renderFeedbackDetails() : ""}
      </Grid>
    </Layout>
  );
};

export default Report;
