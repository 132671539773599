import React, { useContext, useEffect, useState } from "react";

import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";

import { toast } from "material-react-toastify";
import { DialogContext } from "./common/dialog";

import { deleteUser, getUsers } from "../services/userService";
import Layout from "./common/layout";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState();
  const roles = {
    ROLE_USER: { label: "View", colour: "#008585" },
    ROLE_TECHNICIAN: { label: "Technician", colour: "#003463" },
    ROLE_ADMIN: { label: "Admin", colour: "#850000" },
  };

  const { openDialog } = useContext(DialogContext);

  const handleDelete = async (event) => {
    const userId = event.currentTarget.id;

    try {
      await openDialog({
        title: "Delete User?",
        text: "Are you sure you wish to delete this user? All user user data and feedback will be permanently removed.",
      });
      try {
        const { status } = await deleteUser(userId);
        if (status === 204) {
          const newUsers = users.filter((user) => user.id !== parseInt(userId));
          setUsers(newUsers);
          toast.success("User successfully deleted.");
        }
      } catch (ex) {
        toast.error("An error occurred during deletion.");
      }
    } catch (ex) {}
  };

  const getId = (params) => {
    return params.getValue("id");
  };

  const isTechnician = (params) => {
    const rolesArr = params.getValue("roles");
    return rolesArr.includes("ROLE_TECHNICIAN");
  };

  const styleRoles = (rolesArr) => {
    return (
      <React.Fragment>
        {rolesArr.map((role, index) => {
          return (
            <Chip
              size='small'
              label={roles[role].label}
              key={index}
              style={{
                marginRight: "5px",
                backgroundColor: roles[role].colour,
                color: "#fff",
              }}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "avatar",
      headerName: "Avatar",
      width: 100,
      renderCell: (params) => {
        const first = params.getValue("first_name");
        const last = params.getValue("last_name");
        const avatar = params.getValue("avatar");
        return (
          <Avatar src={avatar ? avatar.url : ""}>
            {first && first.charAt(0)}
            {last && last.charAt(0)}
          </Avatar>
        );
      },
    },
    { field: "first_name", headerName: "First name", width: 150 },
    { field: "last_name", headerName: "Last name", width: 150 },
    {
      field: "roles",
      headerName: "Roles",
      width: 250,
      renderCell: (params) => {
        return styleRoles(params.value);
      },
    },
    {
      field: "feedback",
      headerName: "Feedback",
      valueGetter: getId,
      width: 180,
      renderCell: (params) => {
        return (
          <Button
            variant='contained'
            color='primary'
            size='small'
            disabled={!isTechnician(params)}
          >
            <Link
              to={{
                pathname: "/technician/" + params.value,
                state: { fromTitle: document.title },
              }}
            >
              View Feedback
            </Link>
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      valueGetter: getId,
      width: 120,
      renderCell: (params) => {
        return (
          <Button variant='contained' color='primary' size='small'>
            <Link
              to={{
                pathname: "/user/" + params.value,
                state: { fromTitle: document.title },
              }}
            >
              Edit User
            </Link>
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      valueGetter: getId,
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton id={params.getValue("id")} onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    async function retrieveUsers() {
      try {
        const { data } = await getUsers();
        setUsers(data.users);
      } catch (ex) {
        toast.error("An error occurred whilst retrieving the users");
      }
    }

    retrieveUsers();
  }, []);

  return (
    <Layout title='User Management'>
      <Button
        size='small'
        variant='contained'
        color='primary'
        style={{ float: "right" }}
        href='/user/new'
      >
        Add New User
      </Button>
      <Typography variant='h5'>Users</Typography>
      {users ? (
        users.length > 0 ? (
          <div style={{ height: 500, width: "100%", marginTop: "1rem" }}>
            <DataGrid rows={users} columns={columns} pageSize={20} />
          </div>
        ) : (
          <Typography variant='h6'>No Current Users</Typography>
        )
      ) : (
        ""
      )}
    </Layout>
  );
};

export default UserList;
