import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ToastContainer} from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import Leaderboard from './components/leaderboard';
import Technician from './components/technician';
import Survey from './components/survey';
import ReportList from './components/reportList';
import Report from "./components/report";
import UserList from "./components/userList";
import User from "./components/user";
import Login from "./components/login";
import Logout from "./components/logout";
import NotFound from "./components/notFound";
import Unauthorised from "./components/unauthorised";
import ProtectedRoute from "./components/common/protectedRoute";
import theme from './theme';
import {DialogProvider} from "./components/common/dialog";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <ToastContainer />
      <DialogProvider>
        <Switch>
          <ProtectedRoute path="/leaderboard" component={Leaderboard} roles={["ROLE_ADMIN","ROLE_USER"]}/>
          <ProtectedRoute path="/technician/current" component={Technician} roles={["ROLE_TECHNICIAN"]}/>
          <ProtectedRoute path="/technician/:id" component={Technician} roles={["ROLE_ADMIN"]}/>
          <Route path="/survey/:code" component={Survey} />
          <ProtectedRoute path="/reports" component={ReportList} roles={["ROLE_ADMIN"]}/>
          <ProtectedRoute path="/report/:id" component={Report} roles={["ROLE_ADMIN"]}/>
          <ProtectedRoute path="/users" component={UserList} roles={["ROLE_ADMIN"]}/>
          <ProtectedRoute path="/user/current" component={User} roles={["ROLE_USER", "ROLE_ADMIN", "ROLE_TECHNICIAN"]}/>
          <ProtectedRoute path="/user/:id" component={User} roles={["ROLE_ADMIN"]}/>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/not-found" component={NotFound}/>
          <Route path="/unauthorised" component={Unauthorised} />
          <Redirect from="/" exact to="/login" />
          <Redirect to="/not-found" />
        </Switch>
        </DialogProvider>
    </ThemeProvider>
  );
}

export default App;

