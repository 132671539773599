import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

//TODO: Remove material ui
//TODO: Size font to fit max length of rating

const Rating = ({
  numerator,
  denominator = 100,
  decimalPlaces = 0,
  ratingGood = 6,
  ratingOk = 4,
  animate = true,
  showPercent = false,
  size = "100%",
}) => {
  const classes = useStyles();
  const [fontWidth, setFontWidth] = useState(0);
  const [rating, setRating] = useState(0);
  const ref = useRef(null);

  const ratingColor =
    rating < ratingOk
      ? ratingVars.ratingColorBad
      : rating < ratingGood
      ? ratingVars.ratingColorOk
      : ratingVars.ratingColorGood;

  const style = {
    width: size,
    fontSize: fontWidth,
    color: ratingColor,
    background: `conic-gradient(${ratingColor} ${
      (rating / denominator) * 100
    }%, ${ratingVars.ratingColorBackground} 0 100%)`,
  };

  const resizeHandler = () => {
    setFontWidth(ref.current ? ref.current.offsetWidth / 2.5 + "px" : "100%");
  };

  const increase = (value) => {
    const newRating = rating + denominator / 20;

    if (newRating < value) {
      setRating(newRating);
    } else {
      setRating(value);
    }
  };

  useEffect(() => {
    if (numerator) {
      if (animate) {
        setTimeout(increase, 50, numerator);
      } else {
        setRating(numerator);
      }
    }

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  return (
    <div>
      <div ref={ref} className={classes.rating} style={style}>
        <div></div>
        <span>
          {rating ? rating.toFixed(decimalPlaces) : "0"}
          {showPercent ? <small>%</small> : ""}
        </span>
      </div>
    </div>
  );
};

export default Rating;

const ratingVars = {
  barSize: "10%",
  backgroundColor: "#e7f2fa",
  ratingColorDefault: "#2980b9",
  ratingColorBackground: "#c7e1f3",
  ratingColorGood: "#009e22",
  ratingColorOk: "#f5c105",
  ratingColorBad: "#b30000",
};

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
  },
  rating: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    background: ratingVars.ratingColorDefault,
    color: ratingVars.ratingColorDefault,

    "& span": {
      position: "relative",
      display: "flex",
      fontWeight: "bold",
      zIndex: 2,
    },

    "& span small": {
      fontSize: "0.3em",
      fontWeight: "900",
      alignSelf: "center",
    },

    "&::after": {
      content: "''",
      display: "block",
      paddingBottom: "100%",
    },

    "& div": {
      content: "''",
      position: "absolute",
      top: ratingVars.barSize,
      right: ratingVars.barSize,
      bottom: ratingVars.barSize,
      left: ratingVars.barSize,
      background: ratingVars.backgroundColor,
      borderRadius: "inherit",
      zIndex: 1,
    },
  },
}));
