import React, { useEffect, useState, useContext } from "react";

import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { toast } from "material-react-toastify";

import { DialogContext } from "./common/dialog";

//import useDialog from "./common/dialogOld";
import { deleteFeedbackReport, getFeedback } from "../services/feedbackService";
import Layout from "./common/layout";
import { Link } from "react-router-dom";

const ReportList = () => {
  const [reports, setReports] = useState();

  const { openDialog } = useContext(DialogContext);

  const handleDelete = async (event) => {
    const reportId = event.currentTarget.id;

    try {
      await openDialog({
        title: "Delete Feedback Report?",
        text: "Are you sure you wish to delete this feedback report? All feedback will be permanently removed.",
      });
      try {
        const { status } = await deleteFeedbackReport(reportId);
        if (status === 204) {
          const newReports = reports.filter(
            (user) => user.id !== parseInt(reportId)
          );
          setReports(newReports);
          toast.success("Feedback Report successfully deleted.");
        }
      } catch (ex) {
        toast.error("An error occurred during deletion.");
      }
    } catch (ex) {}
  };

  const getId = (params) => {
    return params.getValue("id");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "technician", headerName: "Technician", width: 200 },
    { field: "client", headerName: "Client", width: 200 },
    { field: "project", headerName: "Project", width: 250 },
    {
      field: "completed",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return params.value ? (
          <span style={{ color: "green" }}>Complete</span>
        ) : (
          <span style={{ color: "blue" }}>Awaiting</span>
        );
      },
    },
    {
      field: "completed_date",
      type: "date",
      headerName: "Date",
      width: 130,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString("en-GB");
      },
    },
    {
      field: "view",
      headerName: "View",
      valueGetter: getId,
      width: 130,
      renderCell: (params) => {
        return (
          <Button variant='contained' color='primary' size='small'>
            <Link
              to={{
                pathname: "/report/" + params.value,
                state: { fromTitle: document.title },
              }}
            >
              View Report
            </Link>
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      valueGetter: getId,
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton id={params.getValue("id")} onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    async function retrieveReports() {
      try {
        const { data } = await getFeedback();
        setReports(data.allFeedback);
      } catch (ex) {
        toast.error("An error occurred whilst retrieving the feedback reports");
      }
    }

    retrieveReports();
  }, []);

  return (
    <Layout title='Report Management'>
      <Button
        size='small'
        variant='contained'
        color='primary'
        style={{ float: "right" }}
      >
        <Link
          to={{
            pathname: "/report/new",
            state: { fromTitle: document.title },
          }}
        >
          Create New Report
        </Link>
      </Button>
      <Typography variant='h5'>Feedback Reports</Typography>
      {reports ? (
        reports.length > 0 ? (
          <div style={{ height: 500, width: "100%", marginTop: "1rem" }}>
            <DataGrid
              rows={reports}
              columns={columns}
              pageSize={20}
              sortModel={[
                {
                  field: "completed_date",
                  sort: "desc",
                },
              ]}
            />
          </div>
        ) : (
          <Typography variant='h6'>No Current Reports</Typography>
        )
      ) : (
        ""
      )}
    </Layout>
  );
};

export default ReportList;
