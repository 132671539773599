import { createMuiTheme, responsiveFontSizes }  from '@material-ui/core/styles';


let theme = createMuiTheme({
    palette: {
      primary: { 'main': '#003463' },
      secondary:{ 'main': '#008585'},
      lightBlue:{'main': '#f0f4fa'},
    },
    typography: {
      h1:{
        fontSize: "4rem",
        color: '#fff',
        fontWeight: 600,
      },
      h2:{
        fontSize: "3.5rem",
        fontWeight: 500,
      },      
      h6:{
        fontWeight: 400,
      }
    },
    overrides: {
      MuiAvatar:{
        colorDefault: {
         backgroundColor: "#003463",
        }
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#ffffff00',
          },
        },
      },
      
    },
  });

  theme = responsiveFontSizes(theme);

  export default theme;