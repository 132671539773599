import httpService from "./httpService";

const apiEndpoint = "/user";

export function getUsers(){
    return httpService.get(apiEndpoint);
}

export function getUser(userId){
    return httpService.get(apiEndpoint + '/' + userId);
}

export function saveUser(user){
    if(user.id){
        const body = { ...user };
        delete body.id;
        return httpService.put(apiEndpoint + '/' + user.id, body);
    }

    return httpService.post(apiEndpoint,user);
}

export function deleteUser(userId){
    return httpService.delete(apiEndpoint + '/' + userId);
}

export function uploadAvatar(userId, file, onUploadProgress){
    let formData = new FormData();

    formData.append("avatar", file);

    return httpService.post(apiEndpoint + "/" + userId + "/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
}

export function getAvatar(userId){
    return httpService.get("/technician/" + userId + "/avatar");
}

export function deleteAvatar(userId){
    return httpService.delete(apiEndpoint + "/" + userId + "/avatar");
}