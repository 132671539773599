import { Typography } from "@material-ui/core";
import React from "react";

import Layout from "./common/layout";

const Unauthorised = () => {
  return (
    <Layout title='Unauthorised'>
      <Typography variant='h5'>
        You do not have permission to access the requested resource.
      </Typography>
    </Layout>
  );
};

export default Unauthorised;
