import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

import Account from "../account";

function Layout({
  width = "lg",
  children,
  showHeader = true,
  usePaper = true,
  title,
  center = false,
  showAccount = true,
}) {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Linc Feedback" + (title ? " | " + title : "");
  }, [title]);

  return (
    <Container maxWidth={width} fixed className={classes.container}>
      {showHeader && (
        <div>
          <a href='/'>
            <img
              src='/logo_white.png'
              alt='Linc Logo'
              className={`${classes.logo} ${center ? classes.center : ""}`}
            />
          </a>
          {showAccount && (
            <div className={classes.account}>
              <Account />
            </div>
          )}
          <Typography variant='h1' align={center ? "center" : "left"}>
            {title}
          </Typography>
        </div>
      )}
      {children ? (
        usePaper ? (
          <Paper className={classes.paper}>{children}</Paper>
        ) : (
          <React.Fragment>{children}</React.Fragment>
        )
      ) : (
        <Paper className={classes.paper} style={{ textAlign: "center" }}>
          <CircularProgress />
        </Paper>
      )}
    </Container>
  );
}

export default Layout;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "180px",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(3),
  },
  account: {
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      float: "right",
    },
  },
  center: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  container: {
    paddingTop: theme.spacing(3),
  },
}));
