import httpService from "./httpService";

const apiEndpoint = "/login";

export async function login(credentials){

    const { data } = await httpService.post(apiEndpoint,credentials);
    sessionStorage.setItem("user",JSON.stringify(data.user));

}

export async function logout(){
    sessionStorage.removeItem("user");
    await httpService.get("/logout");
}

export function getCurrentUser(){
    try{
        const user = JSON.parse(sessionStorage.getItem("user"));
        return user;
    }catch(ex){
        return false; 
    }
}

export function getUsername(){
    const user = getCurrentUser(); 
    
    if(user){
        return user.first_name + " " + user.last_name;
    }else{
        return false;
    }
}

export function getUserId(){
    const user = getCurrentUser(); 
    
    if(user){
        return user.id;
    }else{
        return false;
    }
}

export function getUserRoles(){
    const user = getCurrentUser(); 
    
    if(user){
        return user.roles;
    }else{
        return false;
    }
}

export function hasRole(role){

    const user = getCurrentUser();

    return user.roles.includes(role);
}

const auth ={
    login,
    logout,
    getCurrentUser,
    getUsername,
    getUserRoles,
    hasRole,
    getUserId,
}

export default auth;
 