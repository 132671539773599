import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import auth from "../services/authService";
import useForm from "./common/form";
import Layout from "./common/layout";

function Login(props) {
  const [error, setError] = useState({});
  const [redirect, setRedirect] = useState(false);

  const defaultRedirect = () => {
    const id = auth.getUserId();

    if (id) {
      if (auth.hasRole("ROLE_ADMIN")) {
        return "/leaderboard";
      } else {
        if (auth.hasRole("ROLE_TECHNICIAN")) {
          return "/technician/current";
        } else {
          return "/leaderboard";
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setRedirect(defaultRedirect());
  }, []);

  const form = useForm({
    submitForm: async () => {
      try {
        await auth.login({
          email: form.data.email,
          password: form.data.password,
        });
        const { state } = props.location;
        window.location = state ? state.from.pathname : defaultRedirect();
      } catch (ex) {
        if ((ex.response && ex.response.status === 400) || 401) {
          setError({ message: "Invaild username or password." });
        }
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  } else {
    return (
      <Layout width='sm' title='' center showAccount={false}>
        <form>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <Typography variant='h4'>Login</Typography>
            </Grid>
            <Grid item>
              {form.renderInput({
                name: "email",
                label: "Email",
                type: "text",
                required: true,
              })}
            </Grid>
            <Grid item>
              {form.renderInput({
                name: "password",
                label: "Password",
                type: "password",
                required: true,
              })}
            </Grid>
            {error.message && (
              <Grid item>
                <Alert severity='error'>{error.message}</Alert>
              </Grid>
            )}
            <Grid item>{form.renderSave({ label: "Login" })}</Grid>
          </Grid>
        </form>
      </Layout>
    );
  }
}

export default Login;
