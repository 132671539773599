import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedRoute = ({ component: Component, roles, render, ...rest }) => {
  const hasAccess = (hasRoles) => {
    const matchedRoles = roles.filter((role) => hasRoles.includes(role));

    if (matchedRoles.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const hasRoles = auth.getUserRoles();
        if (!hasRoles)
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        if (!hasAccess(hasRoles))
          return (
            <Redirect
              to={{
                pathname: "/unauthorised",
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
