import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TechnicianRating from "./technicianRating";

const Feedback = ({
  client,
  project,
  works,
  comments,
  outcome,
  again,
  completed_date,
  tech_again,
  signature,
  completed_by,
  project_start,
  project_finish,
  ...rest
}) => {
  const outcomes = {
    0: "Below expectations",
    1: "As expected",
    2: "Above expectations",
  };

  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant='h6'>
          Client: <strong>{client}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Project: <strong>{project}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Works Completed: <strong>{works}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Project Dates:{" "}
          <strong>
            {new Date(project_start).toLocaleDateString("en-GB")}
            {" - "}
            {new Date(project_finish).toLocaleDateString("en-GB")}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TechnicianRating {...rest} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6'>
          Final outcome of the works? <strong>{outcomes[outcome]}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6'>
          Would you use our services again?{" "}
          <strong>{again ? "Yes" : "No"}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6'>
          Would you use this technician again?{" "}
          <strong>{tech_again ? "Yes" : "No"}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6'>
          Completed by client:{" "}
          <strong>
            {completed_date
              ? new Date(completed_date).toLocaleDateString("en-GB")
              : ""}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>Comments:</Typography>
        <Typography variant='h6'>
          <strong>{comments || "No comments received"}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Completed by: <strong>{completed_by || ""}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>Signature:</Typography>
        <img src={signature} alt='signature' />
      </Grid>
    </Grid>
  );
};

export default Feedback;
