import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import auth from "../services/authService";
import { Link } from "react-router-dom";

function Account() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (auth.getUsername()) {
    return (
      <div>
        {(auth.hasRole("ROLE_ADMIN") || auth.hasRole("ROLE_USER")) && (
          <Button
            href='/leaderboard'
            color='inherit'
            variant='outlined'
            style={{ marginRight: "1rem", marginBottom: "1rem" }}
          >
            Leaderboard
          </Button>
        )}
        {auth.hasRole("ROLE_ADMIN") && (
          <React.Fragment>
            <Button
              href='/reports'
              color='inherit'
              variant='outlined'
              style={{ marginRight: "1rem", marginBottom: "1rem" }}
            >
              Reports
            </Button>
            <Button
              href='/users'
              color='inherit'
              variant='outlined'
              style={{ marginRight: "1rem", marginBottom: "1rem" }}
            >
              Users
            </Button>
          </React.Fragment>
        )}
        <Button
          color='inherit'
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={handleClick}
          style={{ marginRight: "1rem", marginBottom: "1rem" }}
          startIcon={<AccountCircleIcon />}
        >
          {auth.getUsername()}
        </Button>
        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {auth.hasRole("ROLE_TECHNICIAN") && (
            <Link
              to={{
                pathname: "/technician/current",
                state: { fromTitle: document.title },
              }}
            >
              <MenuItem onClick={handleClose}>My Feedback</MenuItem>
            </Link>
          )}
          <Link
            to={{
              pathname: "/user/current",
              state: { fromTitle: document.title },
            }}
          >
            <MenuItem onClick={handleClose}>My Account</MenuItem>
          </Link>{" "}
          <Link to='/logout'>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Link>
        </Menu>
      </div>
    );
  } else {
    return (
      <Button
        href='/login'
        color='inherit'
        startIcon={<AccountCircleIcon />}
        style={{ marginRight: "1rem", marginBottom: "1rem" }}
      >
        Login
      </Button>
    );
  }
}

export default Account;
